import { useEffect, useState } from 'react';
import {  mtn_icon } from '../../assets';
import { Link } from 'react-router-dom';
import { PiToggleLeftFill, PiToggleRightFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import BeneficiaryPopup from '../../components/dashboards/BeneficiaryPopup';
import { FailureAlert, SuccessAlert } from '../../components';
import { buyAirtime } from '../../controller/buySlice';
import ConfirmationPopup from './ConfirmationPopup';
import OutOfFund from '../../components/dashboards/OutOfFund';
import BuyPinPopup from '../../components/dashboards/BuyPinPopup';
// import ConfirmationPopup from '../../components/dashboards/ConfirmationPopup';

const BuyMTNAirtime = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [amountToPay, setAmountToPay] = useState('');
    const [popup, setPopup] = useState(false);
    const [failurePopup, setFailurePopup] = useState(false);
    const [successPopup, setSuccessPopup] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [pinPopup, setPinPopup] = useState(false)





    const closePinPopup = () => setPinPopup(false);


    const { user } = useSelector((state) => state.auth);
    const { loading, message, error } = useSelector((state) => state.Airtime);

    const dispatch = useDispatch();

    const handleSetBeneficiary = (phone) => {
        setSelectedBeneficiary(phone);
        setPhoneNumber(phone);
        setShowBeneficiary(false); // Hide beneficiary list after selection
    };

    const handleBeneficiaryToggle = () => {
        setShowBeneficiary(!showBeneficiary);
    };

    const handlePhoneNumberChange = (e) => {
        setSelectedBeneficiary(null); // Clear the selected beneficiary if the user starts typing
        setPhoneNumber(e.target.value);
    };

    const handleAmountChange = (e) => {
        const enteredAmount = e.target.value;
        setAmount(enteredAmount);
        const calculatedAmountToPay = (enteredAmount * 0.98).toFixed(2); // Calculate 97% of the amount
        setAmountToPay(calculatedAmountToPay);
    };

    const handlePin = ()=>{

        setPinPopup(true)
    }
    const closeFailurePopup = () => setFailurePopup(false);
    const closeSuccessPopup = () => setSuccessPopup(false);
    const handleClose = () => {
        setPopup(false);

    }
console.log('message is ', message, ' number is ', amount);
    const handleBuy = async () => {
        // Create the payload as an object
        const dataPayload = {
            mobile_number: phoneNumber,
            network: 'MTN',
            amount: parseFloat(amount),
        };

        try {
            const resultAction = await dispatch(buyAirtime(dataPayload));
            if (resultAction.type === buyAirtime.fulfilled.type) {
                // Handle successful airtime purchase
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleConfirmBuy = (e) => {
        e.preventDefault();
        setConfirmPopup(true);
    };

    useEffect(() => {
        if (error) {
            setFailurePopup(true);
        }
    }, [error]);

    // useEffect(() => {

    //     if (message) {
    //         setSuccessPopup(true);
    //     }
    // }, [message]);


    useEffect(() => {
        if (message) {
            // Check if message is an object and convert it to a string if needed
            console.log('Message:', typeof message === 'object' ? JSON.stringify(message) : message);
            setSuccessPopup(true);
        }
    }, [message]);
    
    console.log('Message is:', typeof message === 'object' ? JSON.stringify(message) : message, 'Amount is:', amount);

    if (loading) {
       return (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
      <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>

    <div className="loader" />
            </div>
            </div>
        );
    }

    return (
        <>
            <div className='py-[24px] px-5 flex gap-5 justify-between flex-col md:flex-row items-start'>
                <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto rounded-xl border-solid shadow-lg border-[#E5E5E5]'>
                    <div className='flex items-center justify-center w-full gap-2'>
                        <img src={mtn_icon} alt="MTN icon" />
                        <div className='items-start flex-col'>
                            <h1 className='text-base font-bold'>MTN Airtime</h1>
                            <p className='text-gray-400 text-xs'>Get Instant Top Up</p>
                        </div>
                    </div>
                    <form onSubmit={handleConfirmBuy} className='flex justify-between gap-5'>
                        <div className='flex flex-col gap-4 w-full'>
                            <div>
                                <div className='flex justify-between w-full items-center'>
                                    <p className='text-sm'>Phone</p>
                                    <Link to='' className='text-[#FF7D3B] text-sm font-semibold' onClick={handleBeneficiaryToggle}>Choose Beneficiary</Link>
                                </div>
                                <input type="text" maxLength='11' min="0" required placeholder='07032435485' value={phoneNumber} onChange={handlePhoneNumberChange} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                <div className='flex justify-between w-full items-center'>
                                    <p className='text-xs font-semibold text-nowrap'>Save Number as a Beneficiary</p>
                                    <button type='button' onClick={() => setIsBeneficiary(!isBeneficiary)} className='text-[#FF7D3B] font-semibold text-sm'>
                                        {isBeneficiary ? <PiToggleRightFill /> : <PiToggleLeftFill onClick={() => setPopup(true)} />}
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p>Amount</p>
                                <input type="text"
                                 value={amount}
                                 onChange={handleAmountChange}
                                 min="0" required   
                                className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />

                             
                            </div>
                            <div>
                                <p>Amount to pay</p>
                                <input
                                    type="text"
                                    required
                                    value={amountToPay}
                                    readOnly
                                    className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500'
                                />
                            </div>
                            <button  disabled={loading} className='w-auto h-[46px] px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'>
                                Buy Now
                            </button>
                        </div>
                        <div className='w-[156px]'>
                            {showBeneficiary && (
                                <div className='bg-[#FFF3ED] px-2 py-2 rounded-md h-[300px] overflow-y-scroll'>
                                    {user.beneficiaries.map((item, index) => (
                                        <div key={index} className='flex flex-col gap-2'>
                                            <div className='flex flex-col gap-[0.5px] py-2 border-b-2 border-white border-solid' onClick={() => handleSetBeneficiary(item.phone)}>
                                                <p className='text-xs text-[#FF7D3B] cursor-pointer'>{item.owner}</p>
                                                <p className='text-xs cursor-pointer'>{item.phone}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </form>
                </div>
              


  <OutOfFund/>
            </div>
            {popup && (
                <BeneficiaryPopup
                phone={phoneNumber}
                    onSave={() => setIsBeneficiary(true)}
                    handleClose={handleClose}
                />
            )}
           

           <BuyPinPopup handleBuy={ handleBuy} isVisible={pinPopup} onClose={closePinPopup} />

{
   failurePopup && <FailureAlert onClose={closeFailurePopup} isVisible={failurePopup} message={error}/>
}
{
   successPopup && <SuccessAlert onClose={closeSuccessPopup} isVisible={successPopup} message={message}/>
}
            {confirmPopup && (
                <ConfirmationPopup
                    isVisible={confirmPopup}
                    message={`Dear ${user?.username},  are you sure you want to topup ${phoneNumber} with N${amount} Airtime recharge? Confirm Now.`}
                    onConfirm={() => {
                        handlePin();

                        setConfirmPopup(false);
                    }}
                    onCancel={() => setConfirmPopup(false)}
                />
            )}
        </>
    );
};

export default BuyMTNAirtime;
