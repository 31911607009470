import { useEffect, useState } from 'react';
import { glo_icon} from '../../assets';
import {  Link } from 'react-router-dom';
import Select from 'react-select';
import { PiToggleLeftFill, PiToggleRightFill } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import BeneficiaryPopup from '../../components/dashboards/BeneficiaryPopup';
import { FailureAlert, SuccessAlert } from '../../components';
import {  buyGLoCorporate} from '../../controller/buySlice';
import { toast } from 'react-toastify';
import { getGiftingPlans } from '../../controller/planSlice';
import OutOfFund from '../../components/dashboards/OutOfFund';
import ConfirmationPopup from './ConfirmationPopup';

const BuyGloData = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isBeneficiary, setIsBeneficiary] = useState(false);
    const [showBeneficiary, setShowBeneficiary] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [popup, setPopup] = useState(false)
    const [failurePopup, setFailurePopup] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false)
    const [confirmPopup, setConfirmPopup] = useState(false);



  const { planList } = useSelector((state) => state.giftingPlan);
  const { user } = useSelector((state) => state.auth);
// const [error, setErrors] = useState('')
  const {loading, message, error } =useSelector((state)=>state.buyGloCorporateData);

  useEffect(() => {
    if (error) {
      setFailurePopup(true);
    }
  }, [error]);

  useEffect(() => {
    if (message) {
      setSuccessPopup(true);
    }
  }, [message]);
  
 const dispatch = useDispatch();
//   const handleBuy = () => {
//     dispatch(buyMtnData());
//   };


useEffect(() => {
    // if(!planList){
      dispatch(getGiftingPlans());
    
    // }

}, [dispatch])

console.log('plan is ', planList)



   const  dataId = selectedPlan ? selectedPlan.value : '';
    const mobileNumber = phoneNumber;
   const  networkId = 2

//   const load = {
//     dataId: selectedPlan ? selectedPlan.value : '',
//     mobile_number: phoneNumber,
//     networkId: 1
//   }
console.log('the load is ', {dataId, mobileNumber, networkId})
  const handleBuy = async () => {


    try {
      const resultAction = await dispatch(buyGLoCorporate({dataId, mobileNumber, networkId}));
      if (buyGLoCorporate.fulfilled.match(resultAction)) {
        toast.success(message);
     
      }
    } catch (error) {
        toast.error(error.message)
        console.log(error.message)
     
    }
  };

  console.log('error is ', error)

    const handleSetBeneficiary = (phone) => {
        setSelectedBeneficiary(phone);
        setPhoneNumber(phone);
        setShowBeneficiary(false); 
        };

    const handleBeneficiaryToggle = () => {
        setShowBeneficiary(!showBeneficiary);
       
    };
    const handleConfirmBuy = (e) => {
        e.preventDefault();
        setConfirmPopup(true);
    };


    const handlePhoneNumberChange = (e) => {
        setSelectedBeneficiary(null); // Clear the selected beneficiary if the user starts typing
        setPhoneNumber(e.target.value);
    };

    const beneficiaries = user.beneficiaries;
  

    const customStyles = {
        control: (base, state) => ({
            ...base,
            border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #E5E5E5',
            boxShadow: state.isFocused ? '0 0 0 1px #FF7D3B' : 'none',
            
            '&:hover': {
                border: state.isFocused ? '2px solid #FF7D3B' : '2px solid #FF7D3B'
            }
        })
    };

    const handleStateChange = (selectedOption) => {
        setSelectedPlan(selectedOption);
    };

console.log('success message is ', message)

//   const handlePhoneNumberChange = (value) => {
//     // Ensure only digits are kept and limit to 11 characters
//     const digitsOnly = value.replace(/\D/g, '');
//     if (digitsOnly.length <= 11) {
//       setPhoneNumber(digitsOnly);
//     }
//   };

    const handleClose = () => {

        setPopup(false)
    }
    const closeFailurePopup = () => {

        setFailurePopup(false)
    }
    const closeSuccessPopup = () => {

        setSuccessPopup(false)
    }


    // if(buyGiftingLoading){
    //     return (
    //      <div className="flex justify-center items-center h-screen">
             
    //         <CustomSpinner/>
    //         </div>
    //         );
    //   }

    if (loading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-40'>
      <div className='bg-transparent p-8 gap-4 rounded-xl w-full mx-4 hd-[250px] md:w-[400px] h-[300px] flex flex-col items-center justify-center'>

        <div className="loader" />


            
            </div>
            </div>
        );
    }
     
    return (
    <>
                <div className='py-[24px] px-5 flex gap-5 justify-between flex-col md:flex-row items-start'>
                    <div className='border-[2px] w-full px-5 lg:px-[54px] py-[45px] lg:w-[555px] h-auto rounded-xl border-solid border-[#E5E5E5]'>
                        <div className='flex items-center justify-center w-full gap-2'>
                            <img src={glo_icon} alt="MTN icon" />
                            <div className='items-start flex-col'>
                                <h1 className='text-base font-bold'>GLO Data</h1>
                                <p className='text-gray-400 text-xs'>Get Instant Top Up</p>
                            </div>
                        </div>
                        <form onSubmit={handleConfirmBuy} className='flex justify-between gap-5'>
                            <div className='flex flex-col gap-4 w-full'>
                                <div>
                                    <div className='flex justify-between w-full items-center'>
                                        <p className='text-sm'>Phone</p>
                                        <Link to='' className='text-[#FF7D3B] text-sm font-semibold' onClick={handleBeneficiaryToggle}>Choose Beneficiary</Link>
                                    </div>
                                    <input type="text" maxlength="11" required placeholder='07032435485' value={phoneNumber} onChange={handlePhoneNumberChange} className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                    <div className='flex justify-between w-full items-center'>
                                        <p className='text-xs font-semibold text-nowrap'>Save Number as a Beneficiary</p>
                                        <button type='button' onClick={() => setIsBeneficiary(!isBeneficiary)} className='text-[#FF7D3B] font-semibold text-sm'>
                                            {isBeneficiary ? <PiToggleRightFill /> : <PiToggleLeftFill onClick={()=>setPopup(true)}/>}
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {/* <Select
                                       
                                       {buyGiftingLoading ? 'Loadin..' : 
                                       options={planList?.filter((plan) => plan.networkId === 1 && (plan.dataName === 'MTN GIFTING' )).map((plan) => ({ label: plan.description, value: plan.dataId, amt: plan.amount }))}

                                       } 
                                        styles={customStyles}
                                        classNamePrefix="react-select"
                                        className="w-full"
                                        onChange={handleStateChange}
                                        name="data_plan"
                                    /> */}

<Select
  isLoading={!planList}
  loadingMessage={() => 'Loading...'}
  options={
    planList
      ?.filter((plan) => plan.networkId === 2 && plan.dataName === 'CORPORATE GIFTING')
      .map((plan) => ({
        label: plan.description,
        value: plan.dataId,
        amt: plan.amount,
      }))
  }
  styles={customStyles}
  classNamePrefix="react-select"a
  className="w-full"
  onChange={handleStateChange}
  name="data_plan"
/>
                                </div>
                                <input type="text" required name='amount' value={selectedPlan ? selectedPlan.amt : ''} readOnly className='pl-2 w-full border-[2px] border-solid rounded-lg h-10 placeholder:text-gray-500' />
                                <button type="submit" className='w-auto h-[46px] px-[20px] lg:px-[15px] xl:px-[25px] shadow-md rounded-[10px] bg-[#FF7D3B] text-white cursor-pointer hover:bg-[#FF7D3B] hover:opacity-[.9] transition-all duration-300 hover:scale-95 flex items-center justify-center text-[12px] xl:text-[16px] ease-in'>
                                    Buy Now 
                                </button>
                            </div>

                          

                            <div className='w-[156px] '>
                                {showBeneficiary && (
                                    <div className='bg-[#FFF3ED] px-2 py-2 rounded-md h-[300px] overflow-y-scroll'>
                                        {beneficiaries.map((item, index) => (
                                            <div key={index} className='flex flex-col gap-2'>
                                                <div className='flex flex-col  gap-[0.5px] py-2 border-b-2 border-white border-solid ' onClick={() => handleSetBeneficiary(item.phone)}>
                                                <p className='text-xs text-[#FF7D3B] cursor-pointer' >
                                                    {item.owner} </p>
                                                <p className='text-xs cursor-pointer' >{item.phone}</p>
                                                
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                        </form>
                    </div>

                   <OutOfFund/>


                </div>
       

{
    popup && <BeneficiaryPopup phone={phoneNumber} handleClose={handleClose} />
   
    
}

{confirmPopup && (
                <ConfirmationPopup
                    isVisible={confirmPopup}
                    message={`Dear ${user?.username}, you are about to purchase a ${selectedPlan?.label} plan for ${phoneNumber}.`}

                    // message={`Dear ${user?.username},  are you sure you want to topup ${phoneNumber} with N${amount} Airtime recharge? Confirm Now.`}
                    onConfirm={() => {
                        handleBuy();
                        setConfirmPopup(false);
                    }}
                    onCancel={() => setConfirmPopup(false)}
                />
            )}

{
   failurePopup && <FailureAlert onClose={closeFailurePopup} isVisible={failurePopup} message={error}/>
}
{
   successPopup && <SuccessAlert onClose={closeSuccessPopup} isVisible={successPopup} message={message}/>
}
     
       </>
    );
}

export default BuyGloData;
