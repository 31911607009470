import axios from 'axios';
import { toast } from 'react-toastify';

const axiosClient = axios.create({
  // baseURL: `${process.env.REACT_APP_BASE_URL}`,
  baseURL: 'https://api.payeelord.com/api',
  // baseURL: 'http://payeelordnew.test/api',
  headers: {
    'Content-Type': 'application/json',
  },

});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Corrected method name
  if (token) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
      // console.log(response.data);
      return response;
  },
  (error) => {
      try {
          const { response } = error;
          // if (response.status === 401) {
          //     localStorage.removeItem('token');
          //     toast.error('Oops! token expired. Login again')
          // }
      } catch (err) {
          console.error(err);
      }
      throw error;
  }
);


export default axiosClient;
