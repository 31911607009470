import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosClient from './axiosClient';


export const login = createAsyncThunk('auth/login', async ({ username, password }, { rejectWithValue }) => {
    try {
        const response = await axiosClient.post(`/v1/login`, { username, password });
        localStorage.setItem('token', response.data.data.token);

        return response.data.data;
    } catch (error) {
        
      
      // Check if error.response exists
      if (error.response) {
        return rejectWithValue(error.response.data);
    } else if (error.request) {
      return rejectWithValue({ message: 'Poor internet connection detected, kindly check your internet connection and try again.' });

    } else {
        return rejectWithValue({ message:error.response?.data?.message || error.response?.data?.error});
    }
    }
});



    

// export const register = createAsyncThunk('auth/register', async (userData, { rejectWithValue }) => {
//   try {
//       const response = await axiosClient.post(`/v1/register`, userData);
//       console.log('reg response is', response.data.message);

//       if (response.data.message === 'Registration Succesfull') {
//           return { message: response.data.message };
//       } else {
//           throw new Error('Unexpected registration message');
//       }
//   } catch (error) {
      
    
    
//       return rejectWithValue(error.response.data);
 
  
//   }
// });

export const register = createAsyncThunk('auth/register', async (userData, { rejectWithValue }) => {
  try {
      const response = await axiosClient.post(`/v1/register`, userData);
      console.log('reg response is', response.data.message);



      return response.data.message ;

  } catch (error) {
    console.log('reg error is ',error);
      // Improved error handling
      const message = error.response?.data?.message || error.response?.data?.error || 'An unexpected error occurred';

      console.log(message)
      return rejectWithValue(message);
  }
});


export const createPin = createAsyncThunk('auth/createPin', async (userPin, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/create/pin', userPin, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log(message);
      return message;
    } catch (error) {

        console.log(error.response.data.message)
      return rejectWithValue(error.response.data.message );
    }
  });
export const updatePin = createAsyncThunk('auth/updatePin', async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/update/pin', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log(message);
      return message;
    } catch (error) {

        console.log(error)
        console.log(error.response.data.message || error.response.data.error)
      return rejectWithValue(error.response.data.message || error.response.data.error );
    }
  });
export const updatePassword = createAsyncThunk('auth/updatePassword', async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/update/password', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log(message);
      return message;
    } catch (error) {

        console.log(error)
        console.log(error.response.data.message || error.response.data.error)
      return rejectWithValue(error.response.data.message || error.response.data.error );
    }
  });
export const withdrawRefEarning = createAsyncThunk('auth/withdrawRefEarning', async (payload, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/withdraw/ref-balance', payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log(message);
      return message;
    } catch (error) {

        console.log(error.response.data.message)
      return rejectWithValue(error.response.data.message );
    }
  });
export const checkPin = createAsyncThunk('auth/checkPin', async (userPin, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if(!token) throw new Error ('User not autenticated')
       
      
      const response = await axiosClient.post('/v1/check-pin', userPin, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
    
      return message;
    } catch (error) {

    
      return rejectWithValue(error.response.data.message );
    }
  });
export const generateVirtualWhoGoPay = createAsyncThunk('auth/generateVirtualWhoGoPay', async (phone, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if(!token) throw new Error ('User not autenticated')
       
      
      const response = await axiosClient.post('/v1/generate/palmpay/whopay', phone, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
     
      return message;
    } catch (error) {

        
      return rejectWithValue(error.response.data.message );
    }
  });
export const generateVirtualWhoGoPayPalmpay = createAsyncThunk('auth/generateVirtualWhoGoPayPalmpay', async (phone, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if(!token) throw new Error ('User not autenticated')
       
      
      const response = await axiosClient.post('/v1/generate/palmpay/whopay', phone, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
     
      return message;
    } catch (error) {

        
      return rejectWithValue(error.response.data.message || error.response.data.error);
    }
  });
export const generateVirtualMonnify = createAsyncThunk('auth/generateVirtualMonnify', async (bvn, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if(!token) throw new Error ('User not autenticated')
        console.log('check pin token ',token)
      
      const response = await axiosClient.post('/v1/generate/monnify', bvn, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      
      return message;
    } catch (error) {

      
      return rejectWithValue(error.response.data.message );
    }
  });
export const generateVirtualPayvessel = createAsyncThunk('auth/generateVirtualPayvessel', async (bvn, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if(!token) throw new Error ('User not autenticated')
        console.log('check pin token ',token)
      
      const response = await axiosClient.post('/v1/generate/payvessel', bvn, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
    
      return message;
    } catch (error) {

      return rejectWithValue(error.response.data.message );
    }
  });



export const helpSupport = createAsyncThunk('auth/helpSupport', async (messsageBody, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/help/support', messsageBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
    
      return message;
    } catch (error) {

       
      return rejectWithValue(error.response.data.message );
    }
  });
export const addBank = createAsyncThunk('auth/addBank', async (messsageBody, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/add/account', messsageBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log('Message is ', message);
      return message;
    } catch (error) {

    
      return rejectWithValue(error.response.data.message );
    }
  });




export const addBeneficiary = createAsyncThunk('auth/addBeneficiary', async (userBen, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosClient.post('/v1/add/beneficiary', userBen, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const message = response.data.message;
      console.log(message);
      return message;
    } catch (error) {

       
      return rejectWithValue(error.response.data.message );
    }
  });


export const getUser = createAsyncThunk('auth/getUser', async (_, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      console.log('token is', token);
  
      const state = getState();
      const user = state.auth.user;
   
        const response = await axiosClient.get(`/v1/user`, {
          headers: { Authorization: `Bearer ${token}` }
        });
     
       
        return response.data.user;
    
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });
  

  export const updateUserImage = createAsyncThunk('auth/updateUserImage', async (formData, { rejectWithValue }) => {
    try {
      // Retrieve the token from localStorage
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      
      // Send the POST request with form data
      const response = await axiosClient.post('/v1/upload-profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      // Log the successful response and return the image data
      console.log('Image uploaded successfully', response.data.data);
      return response.data.data;
    } catch (error) {
      // Handle and log errors, then return a rejected value
      console.error('Image upload failed:', error.message || error.response.data);
      return rejectWithValue(error.response?.data || { message: error.message });
    }
  });
  


export const googleLoginCallback = createAsyncThunk(
  'auth/googleLoginCallback',
  async ({ code }, { rejectWithValue }) => {
    try {
      // Log the OAuth code for debugging
      console.log('OAuth code:', code);

      // Send the authorization code to the backend
      const response = await axiosClient.post(`/v1/login/google/callback`, {
        provider: 'google',
        code: code,
      });

  
      localStorage.setItem('token', response.data.token); // Store token in local storage

      return response.data.token;
    } catch (error) {
      console.error('Error during OAuth process:', error.response?.data || error.message);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);


    const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        loading: false,
        error: null,
        message: null,
        createAcctLoading: null,
        beneError: null,
        previousRoute: '/',
		isAuthenticated: false,
        
    },
    reducers: {
        logout: (state) => {
          state.isAuthenticated = false;
          state.loading =false;
          state.user = null;
          localStorage.removeItem('token');
          localStorage.removeItem('redirectPath');
          localStorage.removeItem('hasModalBeenShown');
          state.error = null;
          state.message = null;
        },
        savePreviousRoute: (state, action) => {
          state.previousRoute = action.payload;
        },
        loginSuccess: (state, action) => {
         
          state.isAuthenticated = true;
          // localStorage.setItem('token', action.payload); // Store token in local storage
        },
        clearBeneError: (state) => {
          state.beneError = null;
          state.error = null;
          state.message = null;
        },

        setUserBalance: (state, action) => {
          // state.user.walletBalance = action.payload;
          console.log(action.payload)
          return {
            ...state,
            user:{
              ...state.user,
              walletBalance: action.payload
            }
          }
        }
      
      },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                // state.user = action.payload.;
                state.loading = false;
                state.isAuthenticated =true;
                // localStorage.setItem('token', action.payload.token);

            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || action.error.message;
                state.isAuthenticated = false;
            })
            

            .addCase(register.pending, (state) => {
              state.loading = true;
          })
          .addCase(register.fulfilled, (state, action) => {
              // state.user = action.payload;
              state.loading = false;
            
              
          })
          .addCase(register.rejected, (state, action) => {
              state.loading = false;
              state.error = action.payload?.errors || action.error.message;
          })

            .addCase(getUser.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true;

            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.loading = false;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = false;
                state.error = action.payload?.errors || action.error.errors;
            })
            .addCase(generateVirtualMonnify.pending, (state) => {
                state.createAcctLoading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
                state.error = null
              })
              .addCase(generateVirtualMonnify.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.createAcctLoading = false;
                state.error = null

              })
              .addCase(generateVirtualMonnify.rejected, (state, action) => {
                state.createAcctLoading = false;
                state.isAuthenticated = true;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(generateVirtualWhoGoPay.pending, (state) => {
                state.createAcctLoading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
                state.error = null
              })
              .addCase(generateVirtualWhoGoPay.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.createAcctLoading = false;
                state.error = null

              })
              .addCase(generateVirtualWhoGoPay.rejected, (state, action) => {
                state.createAcctLoading = false;
                state.isAuthenticated = true;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(generateVirtualWhoGoPayPalmpay.pending, (state) => {
                state.createAcctLoading = true;
               
                state.error = null
              })
              .addCase(generateVirtualWhoGoPayPalmpay.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                
                state.createAcctLoading = false;
                state.error = null

              })
              .addCase(generateVirtualWhoGoPayPalmpay.rejected, (state, action) => {
                state.createAcctLoading = false;
                
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(generateVirtualPayvessel.pending, (state) => {
                state.createAcctLoading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
                state.error = null
              })
              .addCase(generateVirtualPayvessel.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.createAcctLoading = false;
                state.error = null

              })
              .addCase(generateVirtualPayvessel.rejected, (state, action) => {
                state.createAcctLoading = false;
                state.isAuthenticated = true;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(createPin.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
                state.error = null
              })
              .addCase(createPin.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.loading = false;
                state.error = null

              })
              .addCase(createPin.rejected, (state, action) => {
                state.loading = false;
                state.isAuthenticated = true;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(updatePin.pending, (state) => {
                state.loading = true;
                state.error = null
              })
              .addCase(updatePin.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.loading = false;
                state.error = null

              })
              .addCase(updatePin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(withdrawRefEarning.pending, (state) => {
                state.loading = true;
                state.isAuthenticated = true; // Set to false while waiting for authentication
                state.error = null;
                state.message  = null;
              })
              .addCase(withdrawRefEarning.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.isAuthenticated = true;
                state.loading = false;
                state.error = null

              })
              .addCase(withdrawRefEarning.rejected, (state, action) => {
                state.loading = false;
                state.message = null;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(checkPin.pending, (state) => {
                state.loading = true;
              })
              .addCase(checkPin.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.loading = false;
              })
              .addCase(checkPin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(helpSupport.pending, (state) => {
                state.loading = true;
              })
              .addCase(helpSupport.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.loading = false;
              })
              .addCase(helpSupport.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || action.error.message; // Simplified error handling
              })
            .addCase(addBeneficiary.pending, (state) => {
                state.loading = true;
              })
              .addCase(addBeneficiary.fulfilled, (state, action) => {
                state.message = action.payload; // Assuming the payload is a message, not the user
                state.loading = false;
              })
              .addCase(addBeneficiary.rejected, (state, action) => {
                state.loading = false;
                state.beneError = action.payload || action.error.message; // Simplified error handling
              })
              .addCase(updateUserImage.pending, (state) => {
                state.loading = true;
              })
              .addCase(updateUserImage.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
              })
              .addCase(updateUserImage.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
              })
              .addCase(addBank.pending, (state) => {
                state.loading = true;
              })
              .addCase(addBank.fulfilled, (state, action) => {
                state.message = action.payload;
                state.loading = false;
              })
              .addCase(addBank.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
              });
    },
});

export const { logout, savePreviousRoute, clearBeneError, loginSuccess, setUserBalance } = authSlice.actions;

export default authSlice.reducer;